import React from "react";
import { SimpleText } from "../components/common/utils";
import { currentLang, systemCookies } from "../components/common/constants";
import useCookie from 'react-use-cookie';

const LanguageSelector: React.FC = () => {
    const keyLanguage = () => {
        switch (currentLang) {
            case 'ESP': return 'es';
            case 'ENG': default: return 'en';
        }
    };
    const [userToken, setUserToken] = useCookie(systemCookies.language, keyLanguage());

    const currentLanguage = () => {
        // invert to show in the menu
        switch (userToken) {
            case 'es': return 'English';
            case 'en': default: return 'Español';

        }
    };

    const handleClick = () => {
        const value = userToken === 'en' ? 'es' : 'en';
        setUserToken(value);
        window.location.reload();
    };

    return (
        <li onClick={handleClick} className="language">
            <SimpleText text={currentLanguage()} />
        </li>);
};



export default LanguageSelector;
