import React, { useState, useEffect } from "react";
import { blogPosts, blogPostsType } from "../../../components/common/sources";
import { headersDataType } from "../../types";
import {
    translate, canonical,
    CollectionTitle, friendlyURL,
    getPathId
} from "../../../components/common/utils";
import SetHeaders from "../../aux/SetHeaders";
import BlogSideBar from "./BlogSideBar";
import InteralPageLinks from "../InteralPageLinks";
import BlogEntryPreview from "./BlogEntryPreview";
import { blogTags, blogTagsType } from "../../common/sources";
import { currentLang } from "../../../components/common/constants";
import BlogNotFound from "./BlogNotFound";


const BlogTagEntries: React.FC = () => {
    const [metaTags, setMetaTags] = useState<headersDataType>();
    const tagId = getPathId(false, 3);
    const entiesFound = blogPosts.filter((entry: blogPostsType) => entry.tagsId.filter((e: number) => e == tagId)?.length)
    if (!entiesFound.length) return <BlogNotFound />;

    const lang: string = '_' + currentLang.toLowerCase();
    const tagSelected = blogTags.filter((t: blogTagsType) => t.id == tagId)[0];
    const title = `${tagSelected[`name${lang}` as keyof blogTagsType]} (${entiesFound.length} ${translate('page-blog-entries')})`;
    const name: string = tagSelected[`name${lang}` as keyof blogTagsType] as string;
    

    useEffect(() => {
        setMetaTags({
            title: title,
            description: translate('page-blog-entries-about') + title,
            canonical: canonical(`blog/tag/${tagId}/${friendlyURL(name)}`),
        });
    }, [tagId]);

    return (
        <>
            {metaTags && <SetHeaders headerInfo={metaTags} />}
            <div className="Blog" >
                <CollectionTitle title={title} />
                <div className="MainListing">
                    <div className="BlogContent">
                        {entiesFound
                            .slice(0, 5)
                            .map((e: blogPostsType, key: number) =>
                                <BlogEntryPreview entry={e} key={key} />
                            )}
                    </div>
                    <div className="BlogSidebar">
                        <BlogSideBar />
                    </div>
                </div>
                <InteralPageLinks />
            </div >
        </>
    );

}
export default BlogTagEntries;