import React from "react";
import { translate, SimpleText } from "../../components/common/utils";
//
import "./InteralPageLinks.scss";

const InteralPageLinks: React.FC = () => {

    return (
        <div className="InteralPageLinks">
            <SimpleText langKey="page-contact-links" />
            <ul>
                <li>
                    <a href="/info" title={translate('page-information-title')}>
                        <SimpleText langKey="page-information-title" icon="icon-arrow-right" />
                    </a>
                </li>
                <li>
                    <a href="/contact" title={translate('page-contact-title')}>
                        <SimpleText langKey="page-contact-title" icon="icon-arrow-right" />
                    </a>
                </li>
                <li>
                    <a href="/brands" title={translate('marcas-message')}>
                        <SimpleText langKey="marcas-message" icon="icon-arrow-right" />
                    </a>
                </li>
                <li>
                    <a href="/newsletter" title={translate('newsletter-message')}>
                        <SimpleText langKey="newsletter-message" icon="icon-arrow-right" />
                    </a>
                </li>
                <li>
                    <a href="/blog" title={translate('page-blog-title')}>
                        <SimpleText langKey="page-blog-title" icon="icon-arrow-right" />
                    </a>
                </li>
                <li>
                    <a href="/nft" title={translate('nfts-link-generic')}>
                        <SimpleText langKey="nfts-link-generic" icon="icon-arrow-right" />
                    </a>
                </li>

                <li>
                    <a href="/copyright" title={translate('menu-copyright')}>
                        <SimpleText langKey="menu-copyright" icon="icon-arrow-right" />
                    </a>
                </li>

            </ul>
        </div>
    );
}

export default InteralPageLinks;