import React from "react";
import { Navigation, SimpleText, translate } from "../components/common/utils";
import { systemCookies } from "../components/common/constants";
import CookieConsent from "react-cookie-consent";
//
import "./Footer.scss";

const Footer: React.FC = () => {
    const cookieModal = (
        <CookieConsent
            location="bottom"
            buttonText={translate('cookie-boton-acept')}
            cookieName={systemCookies.concent}
            buttonStyle={{
                borderRadius: "8px"
            }}
            containerClasses="cookie__consent"
            buttonClasses="cookie__button"
            buttonWrapperClasses="cookie__button_container"
            expires={150}
        >
            {translate('cookie-description-title')}
            <br />
            <span style={{ fontSize: "12px" }}>
                {translate('cookie-description-text')}
            </span>
        </CookieConsent>);

    return (
        <>
            <div className="Footer" >
                <div className="Footer__ndp" >
                    <span className="logo img-logo-ndp" />
                </div>
                <div className="Footer__links" >
                    <ul>
                        <li><SimpleText langKey="autor" /></li>
                        <li><Navigation path='/newsletter' ><SimpleText langKey="newsletter" /></Navigation></li>
                        <li><Navigation path='/copyright' ><SimpleText langKey="derechos-autor" /></Navigation></li>
                        <li className="hideOnMobile"><Navigation path='/contact' ><SimpleText langKey="menu-contacto" /></Navigation></li>
                    </ul>
                </div>
                {cookieModal}
            </div>
        </>
    );
};
export default Footer;
