import React, { useState } from "react";
import {
    CollectionTitle, translate,
    SimpleText, canonical,
    PargraphText
} from "../../components/common/utils";
import SetHeaders from "../aux/SetHeaders";
import { headersDataType } from "../types";
import { nftUrl } from "../../components/common/constants";
import InteralPageLinks from "./InteralPageLinks";
//
import "./Nfts.scss";

const Nfts: React.FC = () => {
    const [metaTags] = useState<headersDataType>({
        title: translate('nfts-page-tile') + " @nicodottaphoto",
        description: translate('nfts-page-subtile'),
        canonical: canonical('nft'),
    });

    const title = <CollectionTitle title={translate("nfts-page-tile")} />;
    return (
        <>
            <SetHeaders headerInfo={metaTags} />
            <div className="Nfts" >
                {title}
                <div className="image">
                    <div className="image-nft" />
                </div>                
                <div className="description">
                    <SimpleText text={translate("nfts-page-subtile")} />
                </div>

                <div className="description text">
                    <PargraphText text={translate("nfts-page-description-1")} />
                </div>

                <div className="description link">
                    <span className="standar-icon icon-arrow-right" />
                    <span>
                        <a href={nftUrl} target="_blank" rel="noreferrer" title={translate('open-new-tab')}>{nftUrl}</a>
                    </span>
                </div>

                <div className="description">
                    <PargraphText text={translate("nfts-page-description-2")} />
                </div>
            </div>
            <InteralPageLinks />
        </>
    );
}

export default Nfts;