import React from "react";
import {
    blogTags, blogTagsType,
    blogPosts, blogPostsType,
} from "../../../components/common/sources";
import {
    translate, friendlyURL,
    Navigation, SimpleText
} from "../../../components/common/utils";
import BlogTagsList from "./BlogTagsList";
import { currentLang } from "../../../components/common/constants";
//
import "./BlogSideBar.scss";

const BlogSideBar: React.FC = () => {
    const lang: string = '_' + currentLang.toLowerCase();
    const idsTags: number[] = blogTags.map((tag: blogTagsType) => tag.id);
    
    const lineEntry = (entryData: blogPostsType, key: number) => {
        const linkPost = `/blog/${entryData.id}/${friendlyURL(entryData.url)}`;
        const title = entryData[`title${lang}` as keyof blogPostsType] as string;
        return (<li key={`lineEntry-${key}`}>
            <Navigation path={linkPost} title={title} >
                <SimpleText text={title} className="link" />
            </Navigation>
        </li>);
    };

    const latest = blogPosts.slice(0, 5).map((e: blogPostsType, key) => lineEntry(e, key));

    const latestEntries = (
        <>
            <div className="title">{translate('page-blog-sidebar-latest')}</div>
            <ul className="LatestList">
                {latest}
            </ul>
        </>
    );


    return <div className="BlogSideBar">
        <div className="title">{translate('page-blog-sidebar-title')}</div>
        <div className="List__container">
            <BlogTagsList taglistIds={idsTags} styleClass="sidebar-tag-list-entry" icon="icon-arrow-right" />
        </div>
        {latestEntries}
    </div>;
}

export default BlogSideBar;

