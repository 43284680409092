import React from "react";
import { blogPostsType } from "../../../components/common/sources";
import {
    PargraphText, trimText,
    Navigation, SimpleText,
    friendlyURL, stripHtml,
} from "../../../components/common/utils";
import BlogTagsList from "./BlogTagsList";
import { currentLang } from "../../../components/common/constants";
import ShowImage from "../../common/ShowImage";


export interface BlogEntryPreviewType {
    entry: blogPostsType;
    key: number;
}
const BlogEntryPreview: React.FC<BlogEntryPreviewType> = ({ ...props }) => {

    const lang: string = '_' + currentLang.toLowerCase();
    const linkPost = `/blog/${props.entry.id}/${friendlyURL(props.entry.url)}`;
    const title = props.entry[`title${lang}` as keyof blogPostsType] as string;
    const body = props.entry[`post${lang}` as keyof blogPostsType] as string;
    return (
        <div key={`entry-${props.key}`} className="entry-post">
            <div>
                <h1>
                    <Navigation path={linkPost} title={props.entry.title_esp}>
                        {title}
                    </Navigation>
                </h1>
            </div>
            <div className="post-preview">
                <div className="post-resume">
                    <PargraphText text={trimText(stripHtml(body), 600)} />
                    {props.entry.imgPath &&
                        <span className="thumb">
                            <Navigation path={linkPost} title={title}>
                                <ShowImage isThumb={true} path="blog/" fileName={props.entry.imgPath} />
                            </Navigation>
                        </span>
                    }
                </div>
                <div className="post-read-more">
                    <Navigation path={linkPost} title={title}>
                        <SimpleText langKey="page-blog-read-more" />
                    </Navigation>
                </div>
            </div>

            <div className="tag-list">
                <SimpleText langKey="page-blog-sidebar-title" icon="icon-arrow-right" />
                <BlogTagsList taglistIds={props.entry.tagsId} styleClass="tag-list-entry" />
            </div>
        </div>
    );

}

export default BlogEntryPreview;
