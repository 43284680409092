import React from "react";
import { getPathId } from "../../../components/common/utils";
import BlogHome from "./BlogHome";
import BlogEntry from "./BlogEntry";
import BlogTagEntries from "./BlogTagEntries";
//
import "./Blog.scss";

const Blog: React.FC = () => {
    const idPath:string = getPathId(false, 2) as string;
    if (!idPath) return <BlogHome />;
    if (idPath == 'tag') return <BlogTagEntries />;
    return <BlogEntry idPath={idPath} />;
}

export default Blog;
