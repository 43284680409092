import React from "react";
import { blogTags, blogTagsType } from "../../common/sources";
import { Navigation, friendlyURL } from "../../../components/common/utils";
import { currentLang } from "../../../components/common/constants";

export interface BlogTagsListType {
    styleClass?: string;
    taglistIds: number[];
    icon?: string;
}
const BlogTagsList: React.FC<BlogTagsListType> = ({ ...props }) => {
    const list = props.taglistIds.map(e => blogTags.filter((t: blogTagsType) => t.id == e).map(f => f)[0]);
    const formatedList = list.map((e, key) => {
        const lang: string = '_' + currentLang.toLowerCase();
        const name: string = e[`name${lang}` as keyof blogTagsType] as string;
        const linkPost = `/blog/tag/${e.id}/${friendlyURL(name)}`;
        return (
            <Navigation path={linkPost} title={name} key={`tagclasification-${key}`}>
                {props.icon && <span className={`icon ${props.icon}`} />}
                <span className={props.styleClass}>{name}</span>
            </Navigation>
        );
    });
    return <>{formatedList}</>;
}

export default BlogTagsList;