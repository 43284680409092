import React from "react";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
//
import "./SharePage.scss";

export interface SharePageType {
    align: string;
    shareUrl: string;
}
const SharePage: React.FC<SharePageType> = ({ ...props }) => (
    <div className={`SharePage ${props.align}`}>
        <div className="SharePage__title">Share:</div>
        <FacebookShareButton url={props.shareUrl}><span className="icon icon-social-facebook" /></FacebookShareButton>
        <LinkedinShareButton url={props.shareUrl} ><span className="icon icon-social-linkedin" /></LinkedinShareButton>
        <TwitterShareButton url={props.shareUrl} ><span className="icon icon-social-x" /></TwitterShareButton >
        <WhatsappShareButton url={props.shareUrl} ><span className="icon icon-social-whatsapp" /></WhatsappShareButton >
    </div >
);

export default SharePage;


