import React, { useState, useEffect } from "react";
import { blogPosts, blogPostsType } from "../../../components/common/sources";
import { currentLang } from "../../../components/common/constants";
import { headersDataType } from "../../types";
import {
    translate, canonical,
    CollectionTitle, SimpleText,
    Navigation, friendlyURL
} from "../../../components/common/utils";
import SetHeaders from "../../aux/SetHeaders";
import BlogTagsList from "./BlogTagsList";
import BlogSideBar from "./BlogSideBar";
import InteralPageLinks from "../InteralPageLinks";
import BlogNotFound from "./BlogNotFound";
import ShowImage from "../../common/ShowImage";
import SharePage from "../../common/SharePage";
//

export interface BlogEntryType {
    idPath: string;
}
const BlogEntry: React.FC<BlogEntryType> = ({ ...props }) => {
    const [metaTags, setMetaTags] = useState<headersDataType>();
    const lang: string = '_' + currentLang.toLowerCase();

    const entry = blogPosts.filter((entry: blogPostsType) => entry.id == Number(props.idPath));
    if (!entry.length) return <BlogNotFound />;


    const linkPost = `blog/${entry[0].id}/${friendlyURL(entry[0].url)}`;

    const translatedEntry = {
        title: entry[0][`title${lang}` as keyof blogPostsType] as string,
        body: entry[0][`post${lang}` as keyof blogPostsType] as string,
        tagsId: entry[0].tagsId,
        imgPath: entry[0].imgPath,
    };
    useEffect(() => {
        setMetaTags({
            title: translatedEntry.title,
            description: translate('page-blog-entries-description') + translatedEntry.title,
            canonical: canonical(linkPost),
        });
    }, [props.idPath]);


    return (
        <>
            {metaTags && <SetHeaders headerInfo={metaTags} />}
            <div className="Blog" >
                <CollectionTitle title={translatedEntry.title} />
                <div className="MainListing">
                    <div className="BlogContent">
                        <div className="post-full">
                            {translatedEntry.imgPath &&
                                <div className="full-image">
                                    <ShowImage isThumb={false} path="blog/" fileName={translatedEntry.imgPath} />
                                </div>
                            }
                            <div dangerouslySetInnerHTML={{ __html: translatedEntry.body }} />
                            <SharePage shareUrl={canonical(linkPost)} align="right"/>
                        </div>
                        <div className="tag-list">
                            <SimpleText langKey="page-blog-tags-description" icon="icon-arrow-right" />
                            <BlogTagsList taglistIds={translatedEntry.tagsId} styleClass="tag-list-entry" />
                        </div>
                        <div className="tag-list">
                            <Navigation path="/blog" title={translate('page-blog-back-start')}>
                                <SimpleText langKey="page-blog-back-start" className="tag-list-entry blue" />
                            </Navigation>
                        </div>
                    </div>
                    <div className="BlogSidebar">
                        <BlogSideBar />
                    </div>
                </div>
                <InteralPageLinks />
            </div>
        </>);
}
export default BlogEntry;
