import React, { useState, useEffect } from "react";
import { headersDataType } from "../../types";
import {
    translate, canonical,
    CollectionTitle, SimpleText,
    Navigation, PargraphText
} from "../../../components/common/utils";
import SetHeaders from "../../aux/SetHeaders";
import BlogSideBar from "./BlogSideBar";



const BlogNotFound: React.FC = () => {
    const [metaTags, setMetaTags] = useState<headersDataType>();

    useEffect(() => {
        setMetaTags({
            title: translate('page-404-title'),
            description: translate('page-404-sub-title'),
            canonical: canonical('404'),
        });
    }, []);

    return (
        <>
            {metaTags && <SetHeaders headerInfo={metaTags} />}
            <div className="Blog" >
                <CollectionTitle title={translate('page-404-title')} />
                <div className="MainListing">
                    <div className="BlogContent">
                        <div className="entry-post">
                            <div className="post-preview">
                                <PargraphText text={translate('page-404-sub-title')} />
                            </div>
                            <div className="post-preview link">
                                <Navigation path="/blog/" title={translate('page-404-go-back')} >
                                    <SimpleText langKey="page-404-go-back" />
                                </Navigation>
                            </div>
                        </div>
                    </div>
                    <div className="BlogSidebar">
                        <BlogSideBar />
                    </div>
                </div>
            </div>
        </>
    );
}
export default BlogNotFound;
