import React, { ReactNode } from "react";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import { currentLang, textContent, languageType, trimTextlength } from "./constants";
import { contentPhotos, contentPhotosType } from "./sources";

export interface NavigationType {
    children: ReactNode;
    confirm?: () => void;
    path: string;
    title?: string;
}

export const Navigation: React.FC<NavigationType> = ({ ...props }) => {
    const navigate = useNavigate();
    const handleClick = (e: React.MouseEvent) => {
        props.confirm && props.confirm();
        e.preventDefault();
        navigate(props.path);
    };
    return (<a href={props.path} onClick={handleClick} title={props.title}>{props.children}</a>);
};


export interface CollectionTitleType {
    styles?: object;
    title: string;
}
export const CollectionTitle: React.FC<CollectionTitleType> = ({ ...props }) => {
    return <div style={props.styles}><h1>{props.title}</h1></div>

};

export interface PargraphTextType {
    styleClass?: string;
    styles?: object;
    text: string;
}

export const PargraphText: React.FC<PargraphTextType> = ({ ...props }) => {
    if (props.text === "") return null;
    return <p style={props.styles} className={props.styleClass}>{props.text}</p>;
};


export const pathCollection = () => {
    const location = useLocation();
    const stripPath = location.pathname.split('/');
    return contentPhotos.filter((content: contentPhotosType) => content.path == '/' + stripPath[2]);
}

export const getPathId = (isPagination: boolean, urlPosition: number) => {
    const location = useLocation();
    const segment = location.pathname.split('/')[urlPosition];
    if (!segment) return null;
    const id = segment.split('?')[0];
    if (!isPagination && id.includes('p-')) {
        return null;
    } else if (isPagination && id.includes('p-')) {
        return parseInt(id.split('p-')[1]);
    } else if (!isPagination) {
        return id ? id : null;
    } else {
        return null;
    }
};

export interface SimpleTextType {
    className?: string;
    langKey?: string;
    text?: string;
    icon?: string;
}
export const SimpleText: React.FC<SimpleTextType> = ({ ...props }) => {
    if (props.langKey && props.langKey.length !== 0) {
        const entry: languageType | null = props?.langKey ? textContent[props.langKey] : null;
        return entry && (
            <div className="standar-text">
                {props.icon && <span className={`standar-icon ${props.icon}`} />}
                <span className={props.className} style={{ display: props.icon && 'inline-flex' }}>
                    {entry[currentLang]}
                </span>
            </div>);
    } else if (props.text && props.text?.length !== 0) {
        return (
            <div className="standar-text">
                {props.icon && <span className={`standar-icon ${props.icon}`} />}
                <span className={props.className} style={{ display: props.icon && 'inline-flex' }}>{props.text}</span>
            </div>);
    } else {
        return null;
    }
}

export const translate = (text: string) => {
    if (text === "") return '';
    return textContent[text][currentLang];
}


export const updateUrlPath = (newpath: string) => {
    window.history.replaceState(null, "", newpath);
}

export const trimText = (text: string, length?: number) => {
    const lng = length ? length : trimTextlength;
    return text.substring(0, lng) + ' (...)';
}

export const canonical = (path: string) => {
    const domain = window.location.origin;
    return `${domain}/${path}`;
}


export const friendlyURL = (url: string) => {
    return url.toString()               // Convert to string
        .normalize('NFD')               // Change diacritics
        .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
        .replace(/\s+/g, '-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        .replace(/&/g, '-and-')          // Replace ampersand
        .replace(/[^a-z0-9-]/g, '')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g, '-')             // Remove duplicate dashes
        .replace(/^-*/, '')              // Remove starting dashes
        .replace(/-*$/, '');             // Remove trailing dashes
}

export const stripHtml = (html:string) => {
   const tmp = document.implementation.createHTMLDocument("New").body;
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}