import React, {useState}  from "react";
import { CollectionTitle, translate, SimpleText, canonical } from "../../components/common/utils";
import SetHeaders from "../aux/SetHeaders";
import { headersDataType } from "../types";
import InteralPageLinks from "./InteralPageLinks";
//
import "./Brands.scss";

const Brands: React.FC = () => {
    const [metaTags] = useState<headersDataType>({
        title: translate('brand-page-title') + " @nicodottaphoto",
        description: translate('brand-page-subtitle'),
        canonical: canonical('brands'),
    });

    const title = <CollectionTitle title={translate("brand-page-title")} />;
    return (
        <>
            <SetHeaders headerInfo={metaTags} />
            <div className="Brands" >
                {title}
                <div className="description">
                    <SimpleText text={translate("brand-page-subtitle")} />
                    <p dangerouslySetInnerHTML={{ __html: translate("brand-page-description") }} />
                </div>
            </div>
            <InteralPageLinks />
        </>
    );
}

export default Brands;